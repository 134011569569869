var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-section"},[_c('gmap-map',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":17,"options":Object.assign({}, {
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: true,
      panControl: false,
      streetViewControl: true,
      fullscreenControl: true,
      disableDefaultUi: true,
      overviewMapControl: true,
      scrollwheel: true,
    },
    _vm.mapOptions)}},_vm._l((_vm.coordinates),function(item,key){return _c('gmap-marker',{key:key,attrs:{"position":_vm.getPosition(item),"clickable":true,"icon":_vm.iconCar},on:{"click":function($event){return _vm.toggleInfo(item, key)}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }